import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { YScroll } from "./components/yScroll";
import { CustomRouter } from './components/customRouter';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CustomRouter>
    <YScroll>
      <App />
    </YScroll>
  </CustomRouter>
);

reportWebVitals();
