import React from 'react';
import '../styles/WhereWeServe.scss';

const WhereWeServe = () => {



  return (
    <>
      <div className="where-we-serve">
        <h1>WHERE WE SERVE</h1>
        <p className='where-we-serve__note'>
          <strong>Please note</strong> that any catering order should be ordered <strong> at least 18 hours prior</strong> to delivery.
          <br /> Any order less than this time will need final confirmation by chef.
        </p>
        <section className="where-we-serve__content">
          <div className="details">
            <h3>Tbilisi International Airport</h3>
            <a href="https://www.flyingchef.ge" style={{ color: '#d4a72a', fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'underline italic' }}>
              flyingchef.ge
            </a>

            <p>
              Chef Levan Kobiashvili's exclusive menu, which you can order at <a href="https://www.flyingchef.ge" style={{ color: '#d4a72a', fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'underline italic' }} target="_blank" rel="noopener noreferrer" aria-label="Flyingchef">
                flyingchef.ge
              </a>. The website is minimalistic and directly focused on selecting, ordering and ensuring delivery of in-flight catering. Any food or drink placed on the platform is available to the client. Along with the name of the dish, the client receives full information about the history of the dish's creation, what taste properties it has, how to serve it, its weight, and the sommelier's advice on which wine to taste it with. In the application you will also see the catering selection of the meal to be followed by the customer
            </p>

          </div>

        </section>

        <section className="where-we-serve__content">

          <div className="details">
            <h3>Kutaisi International Airport</h3>
            <p>
              <i>Comming Soon...</i>
            </p>

          </div>
        </section>

        <section className="where-we-serve__content">
          <div className="details">
            <h3>Batumi International Airport</h3>
            <p>
              <i>Comming Soon...</i>
            </p>

          </div>
        </section>

      </div>

    </>
  );
};

export default WhereWeServe;
