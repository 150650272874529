import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import '../styles/Header.scss';
import logowhite from '../assets/Images/caterer_logo_white.svg';
import logo from '../assets/Images/caterer_logo.svg';

function Header({ isDark = false, footerRef }) {
  const location = useLocation();
  
  const getLinkClass = (linkPage) => {
    return location.pathname === linkPage ? 'active-link' : '';
  };

  const handleContactClick = (e) => {
    e.preventDefault();
    if (footerRef && footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`header ${isDark ? 'dark' : ''}`}>
      <Link to='/'>
        <div className="header__logo">
          {isDark ? <img src={logowhite} alt='logo' /> : <img src={logo} alt='logo' />}
        </div>
      </Link>
      <div className='header__links'>
        <Link to="/" className={getLinkClass('/')}>
          <h4> <span data-content="HOME" aria-hidden="true"></span> HOME </h4>
        </Link>
        <Link to="/about" className={getLinkClass('/about')}>
          <h4> <span data-content="OUR STORY" aria-hidden="true"></span> OUR STORY </h4>
        </Link>
        <Link to="/whereweserve" className={getLinkClass('/whereweserve')}>
          <h4> <span data-content="WHERE WE SERVE" aria-hidden="true"></span> WHERE WE SERVE </h4>
        </Link>
        <Link to="/contact" onClick={handleContactClick} className={getLinkClass('/contact')}>
          <h4> <span data-content="CONTACT" aria-hidden="true"></span> CONTACT </h4>
        </Link>
      </div>
    </div>
  );
}

export default Header;
