import './App.css';
import {
  Route,
  Routes
} from "react-router-dom";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { yScrollContext } from "./components/yScroll";
import { history } from "./components/customRouter";
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import WhereWeServe from './pages/WhereWeServe';
import { ReactLenis } from 'lenis/react';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  const { yScroll, setYscroll } = useContext(yScrollContext);
  const location = useLocation();
  const [currHist, setCurrHist] = useState({
    action: "",
    location: {},
    currentKey: ""
  });
  const footerRef = useRef(null);

  useEffect(() => {
    sessionStorage.setItem('key', location.key);
  }, [location]);

  useEffect(() => {
    const scrolled = () => setYscroll(prev => window.scrollY);
    window.addEventListener('scroll', scrolled);
    return () => {
      window.removeEventListener('scroll', scrolled);
    };
  }, [setYscroll]);

  useEffect(() => {
    return () => {
      sessionStorage.setItem('yvalue', JSON.stringify(yScroll));
    };
  }, [yScroll]);

  useEffect(() => {
    return history.listen(({ location, action }) => {
      if (action === "POP") {
        const fwdKey = sessionStorage.getItem('key');
        const yscroll = sessionStorage.getItem('yvalue');
        location = {
          ...location,
          state: {
            forward: {
              key: fwdKey,
              yscroll: yscroll
            },
            ...location.state
          }
        };
      }
      setCurrHist({
        ...currHist,
        action: action,
        location: {
          ...location
        },
        currentKey: location.key
      });
      console.log(action, location.pathname, location.state, location.key);
    });

  }, [currHist]);

  useLayoutEffect(() => {
    const { action, location, currentKey } = currHist;
    if (action && action === "POP") {
      if (location.state.hasOwnProperty('forward')) {
        if (currentKey === location.state.forward.key) {
          console.log(`forward ${location.state.forward.yscroll}`);
        }
      } else if (location.state.hasOwnProperty('backward')) {
        if (currentKey === location.state.backward.key) {
          console.log(`backward ${location.state.backward.yscroll}`);
          window.scrollTo(0, parseFloat(location.state.backward.yscroll));
        }
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
    console.log(location);
  }, [currHist]);

  return (
    <ReactLenis root>
      <Header footerRef={footerRef} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/whereweserve" element={<WhereWeServe />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer ref={footerRef} />
    </ReactLenis>
  );
}

export default App;
